import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AccessoriesList from '../components/accessoriesList';

import {
  FaMobileAlt,
  FaMobile,
  FaHeadphonesAlt,
  FaPlug,
  FaMagnet
} from 'react-icons/fa';
import '../styles/styles.css';

const IndexPage = ({location}) => {
  var serviceCity;
  if (location.state){
    serviceCity = location.state.serviceCity;
  }
  else {
    serviceCity = "Portland, OR";
  }
  return (
    <Layout serviceCity={serviceCity} currentPage='products' uniqueHeader={'Mobile Device Accessories in ' + serviceCity}>
      <SEO
        title="Phone Accessories"
        description="Looking for phone accessory we have a phone case, tempered glass, headphones, charging cable, car mounts and more"
      />
      <div>
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-one-half accessoiresItemWrapper">
                <div className='accessoiresImgWrapper'>
                  <FaMobileAlt color='#f14668' className='accessoiresImage'/>
                </div>
                <h3 className="is-size-4 accessoiresItems">Phone Case</h3>
                <p className="accessoiresItems">
                  A good phone case is not only a want but a need to protect
                  your phone from accidental drops. Latest cell phones are made
                  with glass in front and back, you have to protect them like
                  never before.
                </p>
              </div>
              <div className="column is-one-half accessoiresItemWrapper">
              <div className='accessoiresImgWrapper'>
                  <FaMobile color='#376fe7' className='accessoiresImage'/>
                </div>
                <h3 className="is-size-4 accessoiresItems">Screen Protectors</h3>
                <p className="accessoiresItems">
                  Screen Protectors (Tempered glass) most of the time is created
                  from much stronger than normal cell phone glass. Properly
                  installed not only will protect your phone from cracking the
                  screen but will protect from scratches. 
                </p>
              </div>
              <div className="column is-one-half accessoiresItemWrapper">
              <div className='accessoiresImgWrapper'>
                  <FaHeadphonesAlt color='#e3237c' className='accessoiresImage'/>
                </div>
                <h3 className="is-size-4 accessoiresItems">Headphones</h3>
                <p className="accessoiresItems">
                  Need headphones we have them, wireless or wired, 3.5mm, type-c
                  or lightning port (Apple products)
                </p>
              </div>
              <div className="column is-one-half accessoiresItemWrapper">
              <div className='accessoiresImgWrapper'>
                  <FaPlug color='#5fbe37' className='accessoiresImage'/>
                </div>
                <h3 className="is-size-4 accessoiresItems">Charging Cable</h3>
                <p className="accessoiresItems">
                  We are always in need of good charging cable, they either
                  disappear or brake. We carry a large variety of USB type-c or
                  lightning port (Apple products) or others
                </p>
              </div>
              <div className="column is-one-half accessoiresItemWrapper">
              <div className='accessoiresImgWrapper'>
                  <FaMagnet color='#f14668' className='accessoiresImage'/>
                </div>
                <h3 className="is-size-4 accessoiresItems">Car Mounts</h3>
                <p className="accessoiresItems">
                  Magnetic car mounts are amazing, easy to use and simple to
                  install. 
                </p>
              </div>
            </div>
          </div>
        </section>
        <AccessoriesList serviceCity={serviceCity}/>
      </div>
    </Layout>
  );
};

export default IndexPage;
