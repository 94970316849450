import React from 'react';

import './style.scss';
import '../styles/styles.css';
import '../styles/accessoriesStyles.css'

import { Link } from 'gatsby';
import products from '../data/products.json';

const AccessoriesList = (props) => {
    return (
        <div className='accessoriesWrapper'>
            <div className='accessoriesGrid'>
            {products.map((product) => {
                    return (
                        <Link key={product.url} to={'/accessories-' + product.url}  className='accessoriesGridItem'
                        state={{serviceCity:props.serviceCity ? props.serviceCity : "Portland, OR"  }}>
                            <img src={product.imageUrl} className='accessoriesGridItemImg'
                            alt="Accessoire "
                            />
                            <p className='accessoriesGridItemName'>{product.name}</p>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

export default AccessoriesList;
